.hero img {
  object-fit: fill;
  width: 80%;
 
  z-index: -1;
  
}
  
  .hero-container {
    height: 100%;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
  
  .hero-container > h1 {
    color: #fff;
    font-size: 100px;
    margin-top: -100px;
  }
  
  .hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .hero-btns {
    margin-top: 32px;
  }
  
  .hero-btns .btn {
    margin: 6px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }
  
  /* Banner Content */
.banner-content {
    display: flex;
    flex-direction: column;
    /* align-items: left; */
    justify-content: left;
    color: #3D2230;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.banner-title {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #3D2230;
}

.banner-paragraph {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
    color: #3D2230;
}

.banner-button {
    background-color: #00000029; /* Faded background */
    color: #fff;
    padding: 15px 40px;
    border: none;
    border-radius: 10px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.banner-button:hover {
    background-color: #3D2230; /* Solid background on hover */
    color: #fff;
}

  @media screen and (max-width: 960px) {
    .hero-container > h1 {
      font-size: 50px;
      margin-top: -150px;
    }

    .banner-title {
        font-size: 1.5rem;
    }

    .banner-paragraph {
        font-size: 1rem;
    }

    .banner-button {
        font-size: 0.9rem;
        padding: 8px 16px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .hero-container > h1 {
      font-size: 10px;
      padding-top: 40px;
      text-align: center;
    }
    
    .banner-title {
      font-size: 10px; /* Reduce font size */
      line-height: 1.2; /* Adjust line spacing */
      max-width: 100%; /* Ensure it doesn’t overflow */
      word-wrap: break-word; /* Break long words if needed */
      overflow-wrap: break-word; /* Ensure text wraps properly */
  }

  .banner-content {
    width: 90%; /* Adjust width for better fit */
    align-items: center !important; /* Center align items */
    text-align: center !important; /* Center text */
}
    .hero-container > p {
      font-size: 30px;
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }
   
  }