.footer-container {
    background-color: #3d2436;
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  
    margin-bottom: 24px;
    padding: 24px;
    color: #fff;
  }
  
  .footer-subscription > p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .footer-subscription-heading {
    margin-bottom: 24px;
    font-size: 24px;
  }
  
  .footer-subscription-text {
    margin-bottom: 24px;
    font-size: 20px;
  }
  
  .footer-input {
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
  }
  
  .footer-links {
    width: 100%;
    max-width: 600px;
    display: flex;
    justify-content: center;
  }
  
  .footer-link-wrapper {
    display: flex;
  }
  
  .footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 8px; /* Reduced from 16px */
    text-align: left;
    width: 215px; /* Reduced from 250px */
    box-sizing: border-box;
  }

  .footer-link-items h3 {
    margin-bottom: 16px;
    color: #fff;
  }
  
  .footer-link-items > h2 {
    color: #fff;
  }
  
  .footer-link-items a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.3rem;
  }
  
  .footer-link-items a:hover {
    color: #fff;
    transition: 0.3s ease-out;
  }
  
  .footer-email-form h2 {
    margin-bottom: 2rem;
  }
  
  .footer-logo {
    display: flex;
    justify-content: center; /* Centers the logo */
    align-items: center;
    margin-bottom: 20px; /* Adds spacing below */
  }

  .footer-logo img {
    width: 150px; /* Increase size */
    height: auto; /* Maintain aspect ratio */
    max-width: 100%;
  }

  .footer-input::placeholder {
    color: #b1b1b1;
  }
  
  /* Social Icons */
  .social-icon-link {
    color: #fff;
    font-size: 24px;
  }
  
  .social-media {
    max-width: 1000px;
    width: 100%;
  }
  
  .social-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;
  }
  
  .social-icons {
    display: flex;
    justify-content:space-evenly;
    align-items: center;
    width: 240px;
  }
  
  .social-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .website-rights {
    color: #fff;
    margin-bottom: 16px;
    text-align: center;
  }
  
  .bold-column h3, .bold-column a {
    font-weight: bold;
    font-family: inherit; /* Ensures it uses the same font as other sections */
    color: #fff; /* Adjust if necessary to match the design */
    text-decoration: none;
  }

  .bold-column a:hover {
      text-decoration: underline; /* Optional: Add hover effect */
  }


  @media screen and (max-width: 1024px) {
    .footer-links {
      padding-top: 2rem;
      display: flex;
      flex-wrap: wrap; /* Allows elements to wrap instead of stacking */
      justify-content: center;
    }
  
    .footer-link-wrapper {
      flex-direction: row; /* Keeps links side by side */
      flex-wrap: wrap; /* Allows elements to wrap */
      justify-content: center;
      width: 100%;
    }
  
    .footer-link-items {
      width: 45%; /* Makes sure two columns are still visible */
      text-align: center;
    }
  
    .footer-logo {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
    }
  
    .social-media-wrap {
      flex-direction: row; /* Keeps social media items in one line */
      justify-content: space-between;
      width: 100%;
      padding: 10px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .footer-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%;
        padding: 20px 0; /* Adds some spacing */
    }

    .footer-links {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; /* Center horizontally */
        width: 100%;
    }

    .footer-link-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; /* Ensures full centering */
        text-align: center;
        width: 100%;
    }

    .footer-link-items {
        display: flex;
        flex-direction: column;
        align-items: center; /* Ensures all links inside are centered */
        justify-content: center;
        text-align: center;
        width: 100%;
        margin-bottom: 20px;
    }

    .footer-link-items a {
        display: block; /* Makes each link take up full width */
        text-align: center; /* Centers the text */
        margin: 5px 0; /* Adds space between links */
    }

    .footer-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .footer-logo img {
        max-width: 150px; /* Adjust logo size if needed */
    }

    .social-media {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; /* Ensures centering */
        text-align: center;
        width: 100%;
        margin-top: 20px;
    }

    .social-media-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; /* Ensures full centering */
        text-align: center;
        width: 100%;
    }

    .website-rights {
        width: 90%;
        text-align: center;
        margin: 0 auto;
    }
}



  