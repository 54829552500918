/* Navbar */
.navbar {
    background: transparent;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    transition: background-color 0.5s ease;
}

.navbar-container {
    display: flex;
    align-items: center;
    height: 80px;
    max-width: 1500px;
    width: 100%;
    padding: 0 20px;
    position: relative;
}

/* Align logo to the left */
.navbar-logo {
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    position: absolute;
    left: 20px;
}

.navbar-logo-img {
    height: 40px;
    width: auto;
}

/* Navbar background color transitions */
.navbar-transparent {
    background-color: transparent;
}

.navbar-light-gray {
    background-color: whitesmoke
}

/* Centering the Navigation Menu */
.nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
    justify-content: center;
    flex-grow: 1;
    margin: 0 auto; /* Centering the nav-menu */
}

.nav-item {
    height: 80px;
}

.nav-links {
    color: black;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    cursor: pointer;
    transition: color 0.3s ease;
}

.nav-links:hover {
    color: #555;
}

/* Menu Icon for Mobile View */
.menu-icon {
    display: none;
    font-size: 1.8rem;
    cursor: pointer;
    color: black;
    position: absolute;
    right: 20px;
}

/* Desktop Login Button */
.nav-login-button {
    text-align: center;
    padding: 8px 16px;
    border: 1px solid black;
    background-color: transparent;
    color: black;
    cursor: pointer;
    text-decoration: none;
    border-radius: 4px;
}

.nav-login-button:hover {
    background-color: black;
    color: white;
}

/* For Mobile View */
@media screen and (max-width: 960px) {
    .menu-icon {
        display: block;
        font-size: 1.8rem;
        cursor: pointer;
        color: black;
    }

    /* Mobile Menu */
    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
        background: #242222;
    }

    .nav-menu.active {
        left: 0;
        opacity: 1;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
        color: white;
        text-decoration: none;
        transition: background-color 0.3s ease;
    }

    /* Change background color on hover */
    .nav-links:hover {
        background-color: #444;
        color: white;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        text-decoration: none;
        font-size: 1.5rem;
        background-color: transparent;
        color: white;
        padding: 14px 20px;
        border: 1px solid white;
        transition: background-color 0.3s ease, color 0.3s ease;
    }

    /* Change background color for login button on hover */
    .nav-links-mobile:hover {
        background-color: white;
        color: #242424;
    }
}
