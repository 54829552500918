/* Team Container */
.team-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
}

/* Tab Navigation */
.team-tabs {
    display: flex;
    justify-content: center; /* Center the tabs */
    gap: 50px; /* Spacing between tabs */
    margin-bottom: 40px;
    font-size: 1.5rem; /* Make the font size larger */
    flex-wrap: wrap;
}

.team-tab {
    font-weight: 500;
    cursor: pointer;
    padding: 10px 20px;
    position: relative;
    transition: color 0.3s ease;
}

.team-tab.active {
    color: #000;
    font-weight: 700;
}

.team-tab.active::after {
    content: '';
    position: absolute;
    width: 50%;
    height: 2px;
    background-color: #b29658;
    bottom: -5px;
    left: 25%;
}

/* Team Grid */
.team-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Exactly 3 items per row */
    gap: 30px; /* Space between items */
}

/* Team Member Item */
.team-item {
    cursor: pointer;
    transition: transform 0.3s ease;
    text-align: center;
}

.team-item:hover {
    transform: translateY(-10px);
}

/* Ensure consistent image size */
.team-image {
    width: 200px; /* Set a fixed width */
    height: 250px; /* Set a fixed height */
    object-fit: cover; /* Ensure image covers the space uniformly */
    margin-bottom: 15px;
}

/* Team Info */
.team-info h3 {
    font-size: 1.1rem;
    color: #000;
    font-weight: 600;
}

.team-info p {
    font-size: 0.9rem;
    color: #555;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .team-grid {
        grid-template-columns: repeat(2, 1fr); /* 2 items per row for medium screens */
    }
}

@media (max-width: 768px) {
    .team-grid {
        grid-template-columns: 1fr; /* Stack items vertically on small screens */
    }

    .team-tabs {
        flex-direction: column; /* Stack tabs vertically */
        align-items: center; /* Center align the tabs */
    }
    
    .team-tab {
        padding: 10px; /* Reduce padding to fit smaller screens */
        font-size: 1.5rem; /* Adjust font size for mobile */
    }
}
