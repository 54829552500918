/* Main Items Container */
.items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    padding: 20px;
}

.items h1 {
    font-size: 3rem;
    padding-bottom: 20px;
    padding-top: 30px;
    color: #3D2230;
    text-align: left; /* Left-align the heading */
    width: 100%; /* Ensure the heading spans the full width */
    max-width: 800px; /* Adjust this width to match your layout */
    margin-left: 0; /* Remove centering margin */
}

.items h1::after {
    content: '';
    display: block;
    width: 33.33%; /* 2/3 of the width */
    height: 5px; /* Thickness of the line */
    background-color: #BB9959; /* Line color */
    margin-top: 10px;
}

/* Item Divs for Each Section */
.items_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgb(253, 253, 253);
    max-width: 60%; /* Adjusted width */
    margin: 0 auto;
    padding: 20px;
    text-align: left;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.item-image {
    width: 35%; /* Adjusted image width */
    height: auto;
    object-fit: cover;
    margin-right: 20px; /* Spacing between image and content */
}

.item-content {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.item-title {
    font-size: 2rem;
    color: #3D2230;
    margin-bottom: 10px;
}

.item-title::after{
    content: '';
    display: block;
    width: 200px; 
    height: 5px; /* Thickness of the line */
    background-color: #BB9959; /* Line color */
    margin-top: 10px;
}

.item-description {
    font-size: 1rem;
    line-height: 1.6;
    color: #282828;
}

.items h1 {
    font-size: 3rem;
    padding-bottom: 20px;
    color: #3D2230;
}

/* Info Boxes Container */
.info-boxes {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns on desktop */
    gap: 10px;
    max-width: 700px;
    justify-items: center;
    align-items: center;
    margin-bottom: 40px; /* Space below the info-boxes */
}

/* Info Box Styling */
/* Info Boxes Container */
.info-boxes {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns on desktop */
    gap: 20px; /* Increased gap for larger boxes */
    max-width: 900px; /* Increased width to accommodate larger boxes */
    justify-items: center;
    align-items: center;
    margin-bottom: 40px; /* Space below the info-boxes */
}

/* Info Box Styling */
.info-box {
    background-color: #3D2230;
    border: 1px solid #ddd;
    border-radius: 10px;
    position: relative;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 60px; /* Increased padding for larger content space */
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    width: 400px; /* Increased width */
    height: 250px; /* Increased height */
}

.info-box p {
    font-size: 1.5rem; /* Increased font size for larger boxes */
    color: white;
}

/* Hidden "Learn More" by default */
.learn-more {
    position: absolute;
    bottom: 20px; /* Adjusted position for larger box */
    left: 50%;
    transform: translateX(-50%);
    font-size: 1rem; /* Increased font size */
    color: #3D2230;
    display: none;
}

/* Hover effect for info boxes */
.info-box:hover {
    background-color: #E2D7FF;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2); /* More pronounced shadow */
}

.info-box:hover p {
    color: #3D2230;
}

.info-box:hover .learn-more {
    display: block;
}

/* Responsive styling */

/* For tablets and small desktop */
@media screen and (max-width: 1024px) {
    .items_item {
        flex-direction: column;
        text-align: center;
        margin-bottom: 2rem;
        max-width: 100%;
    }

    .item-image {
        width: 100%;
        margin-bottom: 20px;
    }

    .item-content {
        width: 100%;
    }
}

/* Mobile view: Stack the info boxes vertically */
@media screen and (max-width: 768px) {
    .info-boxes {
        grid-template-columns: 1fr; /* Stack boxes vertically */
        max-width: 100%; /* Full width on mobile */
    }

    .info-box {
        width: 90%; /* Make the boxes take most of the mobile screen width */
        height: auto; /* Allow height to adjust to content */
        padding: 40px; /* Adjusted padding for mobile */
    }

    

    .items_item {
        flex-direction: column; /* Stack image on top of the content */
        text-align: center;
        max-width: 100%; /* Full width on mobile */
    }

    .item-image {
        width: 100%; /* Full width image on mobile */
        margin-bottom: 20px; /* Spacing between image and content */
        order: 1; /* Ensure image comes first */
    }

    .item-content {
        width: 100%; /* Full width content */
        text-align: center;
        order: 2; /* Ensure description comes second */
    }
}
