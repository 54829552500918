.contact-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  max-width: 1920px;
  margin: 0 auto;
  height: 75vh; /* Ensure it takes up the full viewport height */
  padding: 20px; /* Optional: Add some inner spacing */
  box-sizing: border-box; /* Include padding in height/width */
}

.image-container {
  flex: 0 0 20%; /* Reserve 20% of the space for the image */
  display: flex;
  align-items: center; /* Center the image vertically */
  justify-content: center; /* Center the image horizontally */
  padding-right: 20px; /* Add spacing to the right of the image */
}

.image-container img {
  width: 100%; /* Fit the container width */
  height: auto; /* Maintain aspect ratio */
  max-height: 800px;
  border-radius: 10px; 
}


.contact-page {
  flex: 1; /* Allow this section to take the remaining space */
  display: flex;
  flex-direction: row;
  padding-top: 110px;
  gap: 30px;
  line-height: 1.8;
  max-width: 1200px;
}

.contact-info {
  flex: 0 0 30%;
  padding-right: 20px;
  padding: 20px;
  background-color: #f9f9f9; /* Optional: Add subtle background for distinction */
}

.contact-info p {
  font-size: 1rem;
  line-height: 1.3;
  margin-bottom: 10px;
}

.contact-title {
  color: #4a2f2f;
}

.underline {
  border-bottom: 3px solid #d4af37;
  width: 50px;
  margin-bottom: 20px;
}

.contact-form {
  flex: 1;
}

.form-section h3 {
  margin-bottom: 10px;
  color: #4a2f2f;
  font-weight: bold;
}

.input-group {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
}

.input-field {
  flex: 1;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.input-full {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  height: 80px;
}

.recaptcha {
  margin: 20px 0;
}

.submit-button {
  background-color: #4a2f2f;
  color: #fff;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  display: inline-block;
  transition: all 0.3s ease;
}

.submit-button:hover {
  background-color: #3b2626;
}

.submit-button:active {
  background-color: #2a1a1a;
}

/* Mobile Responsive Design */
@media (max-width: 768px), (max-width: 960px) {
  .contact-wrapper {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center all content */
    justify-content: center; /* Center vertically */
    width: 100%;
    margin: 0 auto;
    padding:590px 120px;
    text-align: center; /* Center all text */
  }

  .image-container {
    display: none; /* Hide the image container */
  }

  .contact-page {
    display: flex;
    flex-direction: column; /* Stack content vertically */
    align-items: center;
    justify-content: center;
    gap: 20px; /* Space between stacked items */
    padding-top: 30px; /* Adjust spacing */
    max-width: 100%; /* Full width */
  }

  /* Moves Contact Info to the Top */
  .contact-info {
    order: -1; /* Ensures this section appears first */
    width: 250px; /* Adjust width */
    background-color: #f9f9f9; /* Retain subtle background */
    border-radius: 8px; /* Add rounded corners */
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    max-height: 200px;
  }

  .contact-title {
    font-size: 1.8rem; /* Adjust title size */
    text-align: center;
  }

  .contact-info p {
    font-size: 1rem;
    margin: 5px 0;
  }

  /* Contact Form Styling */
  .contact-form {
    width: 300px;
    padding: 20px;
  }

  .form-section {
    width: 100%;
    text-align: center;
  }

  .input-group {
    display: flex;
    flex-direction: column; /* Stack inputs */
    align-items: center;
    width: 100%;
    gap: 10px;
  }

  .input-field,
  .input-full,
  select,
  .textarea {
    width: 100%;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }

  /* Center reCAPTCHA */
  .recaptcha {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }

  /* Center Submit Button */
  .submit-button {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .submit-button div {
    display: inline-block;
    background-color: #4a2f2f;
    color: #fff;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    user-select: none;
  }
}
