/* Insights Section */
.insights-section {
    font-family: Arial, sans-serif;
    padding: 40px;
    background-color: #fff;
    color: #2c1f27;
  }
  
  .insights-header {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .header-title {
    width: 60%;
    text-align: left;
  }
  
  .header-title h2 {
    font-size: 2.5rem;
    color: #341a24;
    font-weight: bold;
    margin: 0;
  }
  
  .divider {
    width: 30%;
    height: 3px;
    background-color: #d6b75b;
    border: none;
    margin: 10px 0;
  }
  
  .header-description {
    width: 30%;
    text-align: left;
  }
  
  .videos-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  
  .video-block {
    background-color: #f3e8ff;
    border-radius: 8px;
    padding: 20px;
    width: 500px;
    text-align: left;
  }
  
  .video-placeholder {
    background-color: #e6dbff;
    border-radius: 8px;
    height: 150px;
    margin-bottom: 15px;
  }
  
  .video-block h3 {
    font-size: 1.2rem;
    margin: 10px 0;
  }
  
  .video-block p {
    font-size: 0.9rem;
    line-height: 1.5;
  }
  
  /* Guides Section */
  .guides-section {
    background-color: #341a24;
    color: #fff;
    padding: 120px 30px;
    
  }
  
  .guides-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0 auto;
    gap: 40px;
  }
  
  .guides-text {
    width: 60%;
    text-align: left;
  }
  
  .guides-text h2 {
    font-size: 2.5rem;
    font-weight: bold;
    margin: 0 0 10px 0;
  }
  
  .guides-text p {
    font-size: 1rem;
    line-height: 2.0;
  }
  
  .guides-links {
    width: 35%;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .guide-item {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .guide-download {
    text-decoration: none;
    color: inherit;
    display: flex;
    align-items: center;
  }

  .guide-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  .events-section {
    background-color: #fff;
    color: #2c1f27;
    padding: 40px 20px; /* Same padding as other sections */
    margin-top: 40px;
  }
  
  .events-header {
    max-width: 1200px; /* Align with the rest of the page */
    margin: 0 auto; /* Centers the title */
    text-align: left; /* Aligns the title to the left */
  }
  
  .events-header h2 {
    font-size: 2.5rem;
    color: #341a24;
    font-weight: bold;
    margin: 0;
  }
  
  .divider {
    width: 50px;
    height: 3px;
    background-color: #d6b75b;
    margin: 10px 0;
  }
  
  /* General Section Styles */
.events-section {
    background-color: #fff;
    color: #2c1f27;
    padding: 40px 20px;
    margin-top: 40px;
  }
  
  .events-header {
    max-width: 1200px;
    margin: 0 auto;
    text-align: left;
  }
  
  .events-header h2 {
    font-size: 2.5rem;
    color: #341a24;
    font-weight: bold;
  }
  
  .divider {
    width: 50px;
    height: 3px;
    background-color: #d6b75b;
    margin: 10px 0;
  }
  
  .events-container {
    max-width: 1200px;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .event-block {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .event-details {
    width: 70%;
  }
  
  .event-details h3 {
    font-size: 1.5rem;
    margin: 0 0 10px 0;
  }
  
  .event-date {
    font-size: 0.9rem;
    color: #6b6b6b;
    margin: 0 0 10px 0;
  }
  
  .event-description {
    font-size: 1rem;
    line-height: 1.5;
  }
  
  .event-action {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .visit-site-button {
    display: inline-block;
    background-color: #d6b75b;
    color: #fff;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center;
  }
  
  .visit-site-button:hover {
    background-color: #c5a64b;
  }
  
  /* Show More Button Styles */
  .show-more-container {
    text-align: center;
    margin-top: 20px;
  }
  
  .show-more-button {
    background-color: #341a24;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .show-more-button:hover {
    background-color: #29191e;
  }
  
  .faq-section {
    background-color: #fff;
    color: #2c1f27;
    padding: 40px 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .faq-header {
    text-align: left;
    margin-bottom: 30px;
  }
  
  .faq-header h2 {
    font-size: 2.5rem;
    color: #341a24;
    font-weight: bold;
  }
  
  .divider {
    width: 50px;
    height: 3px;
    background-color: #d6b75b;
    margin: 10px 0;
  }
  
  .faq-header p {
    font-size: 1rem;
    line-height: 1.5;
    color: #555;
  }
  
  /* FAQ Container */
  .faq-container {
    border-top: 1px solid #ddd;
  }
  
  .faq-item {
    border-bottom: 1px solid #ddd;
    padding: 15px 0;
    cursor: pointer;
  }
  
  .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    font-weight: bold;
    color: #341a24;
  }
  
  .faq-icon {
    font-size: 1rem;
    color: #6b6b6b;
  }
  
  .faq-answer {
    margin-top: 10px;
    padding-left: 15px;
    font-size: 1rem;
    line-height: 1.5;
    color: #555;
  }
  
  .faq-answer p {
    margin: 5px 0;
  }
  
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .insights-header,
    .guides-content {
      flex-direction: column;
    }
  
    .header-title,
    .header-description,
    .guides-text,
    .guides-links {
      width: 100%;
    }
  
    .divider {
      width: 50%; /* Adjust divider width for small screens */
    }
  }
  