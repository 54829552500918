/* Team Section Container */
.team-container11 {
    display: flex; /* Flexbox layout for side-by-side */
    justify-content: space-between; /* Space between text and image */
    align-items: center; /* Vertically align text and image */
    padding: 40px;
    max-width: 1200px;
    margin: 60px auto;
    flex-wrap: wrap; /* Ensure responsiveness by wrapping content on smaller screens */
}

/* Text Container */
.text-container11 {
    flex: 1; /* Take up equal space as the image */
    padding-right: 20px; /* Add spacing between text and image */
    max-width: 600px;
}

.text-container11 h1 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #3D2436;
    margin-bottom: 20px;
}

.text-container11 h1::after{
    content: '';
    display: block;
    width: 200px; 
    height: 5px; /* Thickness of the line */
    background-color: #BB9959; /* Line color */
    margin-top: 10px;
}

.text-container11 p {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #282828;
}

/* Image Container */
.image-container11 {
    flex: 1; /* Take up equal space as the text */
    display: flex;
    justify-content: center;
}

.image-container11 img {
    max-width: 100%; /* Ensure image is responsive */
    height: auto;
    border-radius: 10px;
}
.bold{
    font-weight: bold;
}
/* Responsive Design for Smaller Screens */
@media (max-width: 768px) {
    .team-container11 {
        flex-direction: column; /* Stack content vertically on small screens */
    }

    .text-container11 {
        padding-right: 0;
        text-align: center; /* Center text on smaller screens */
    }

    .image-container11 {
        margin-top: 20px; /* Add spacing between text and image */
    }
}

button,
a[href*="Board of Directors"],
a[href*="Experts"] {
    display: none !important;
    visibility: hidden !important;
}