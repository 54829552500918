.hero img {
    object-fit: fill;
    width: 80%;
   
    z-index: -1;
    
  }
    
    .hero-container {
      height: 100%;
      width: auto;
      display: flex;
      flex-direction: column;
      justify-content: left;
      align-items: center;
      box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
      object-fit: contain;
    }
    
    .hero-container > h1 {
      color: #fff;
      font-size: 100px;
      margin-top: -100px;
    }
    
    .hero-container > p {
      margin-top: 8px;
      color: #fff;
      font-size: 32px;
      font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
        'Lucida Sans', Arial, sans-serif;
    }