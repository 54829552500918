/* General Container */
.items_item1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  background-color: #f9f9f9;
  font-family: 'Arial', sans-serif;
}

/* Desktop Layout */
.box-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 40px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

/* Left side content (Who We Serve) */
.item-content {
  flex: 1;
  padding-right: 20px;
}

/* Styling for the title */
.item-title {
  font-size: 2rem;
  font-weight: bold;
  color: #3d2436;
  margin-bottom: 20px;
}

/* List for description */
.item-description {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.item-description li {
  font-size: 1.2rem;
  color: #3d2436;
  margin-bottom: 10px;
  cursor: pointer;
}

.item-description li:hover {
  color: #b29658;
  transition: color 0.3s ease;
}

/* Right side image */
.item-image {
  flex: 1;
  max-width: 500px;
  width: 100%;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Wealth Container */
.wealth-container {
  margin-top: 60px;
  width: 100%;
  text-align: center;
}

.wealth-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #3d2436;
  margin-bottom: 10px;
}

.wealth-subtitle {
  font-size: 1.5rem;
  color: #b29658;
  margin-bottom: 50px;
}

/* Cards Container */
.wealth-cards {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  flex-wrap: wrap;
}

/* Each individual card styling */
.wealth-card {
  background-color: #3d2436;
  color: #fff;
  width: 30%;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.wealth-card:hover {
  transform: translateY(-10px);
}

.card-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.card-description {
  font-size: 1rem;
  line-height: 1.6;
}

/* Additional Styles for wealth-cards1 */
.wealth-cards1 {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  flex-wrap: wrap;
}

/* Each individual card styling */
.wealth-card1 {
  background-color: #E2D7FF;
  color: #3D2230;
  width: 30%;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.wealth-card1:hover {
  transform: translateY(-10px);
}

.card-title1 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: #3D2230;
}

.card-description1 {
  font-size: 1rem;
  line-height: 1.6;
  color: #3D2230;
}

/* Button Styling */
.wealth-button {
  background-color: #b29658;
  color: #fff;
  font-size: 1.25rem;
  font-weight: 500;
  padding: 12px 24px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  margin-top: 40px;
  transition: background-color 0.3s ease;
}

.wealth-button:hover {
  background-color: #9c8b66;
}

.sv-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  background-color: #E2D7FF; /* Light purple background */
  padding: 40px 60px; /* Adjusted for better spacing */
  border-radius: 12px;
  margin-bottom: 20px;
  width: 100%;
  min-height: 200px;
  gap: 20px; /* Ensures spacing between title and description */
}

/* Flexbox to keep title and description side by side */
.sv-content {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px;
}

.sv-title {
  flex: 3; /* 30% width */
  font-size: 28px; /* Slightly reduced for better balance */
  font-weight: bold;
  color: #2c1d34; /* Dark text */
  margin-bottom: 0; /* Remove extra spacing */
  text-align: left; /* Align title to the left */
}

.sv-des {
  flex: 7; /* 70% width */
  font-size: 18px;
  color: #5a5a5a;
  line-height: 1.5; /* Improved readability */
  text-align: left;
}


.pink-container {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  align-items: center;
  background-color: #E2D7FF; /* Soft pink background */
  padding: 60px 80px; /* Increase padding for a bigger container */
  border-radius: 12px;
  margin-bottom: 20px;
  width: 100%;
  min-height: 200px; /* Makes it taller */
}

.pink-content {
  flex: 7; /* 70% width */
}

.pink-title {
  font-size: 32px;
  font-weight: bold;
  color: #2c1d34; /* Dark text */
  margin-bottom: 10px;
}

.pink-subtitle {
  font-size: 20px;
  font-style: italic;
  color: #b38b60; /* Gold subtitle */
}

.pink-button {
  flex: 3; /* 30% width */
  display: flex;
  justify-content: flex-end;
}

.btn.get-started {
  background: #b38b60;
  color: white;
  padding: 10px 20px;
  border-radius: 6px;
  border: none;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}



/* Responsive adjustments */
@media (max-width: 1024px) {
  .wealth-card {
      width: 45%;
  }
  .wealth-card1 {
      width: 45%;
  }
}

@media screen and (max-width: 768px) {
  .box-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
  }

  .item-content {
      padding: 0;
      text-align: center;
      width: 100%;
      margin-bottom: 20px;
  }

  .item-title {
      font-size: 1.8rem;
      margin-bottom: 15px;
      text-align: center;
      display: block;
  }

  .item-image {
      width: 100%;
      height: auto;
      margin-bottom: 10px;
  }

  .mobile-options {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
  }

  .mobile-option {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 350px;
      margin-bottom: 20px;
      cursor: pointer;
  }

  .mobile-option img {
      width: 100%;
      height: auto;
      border-radius: 8px;
  }

  .mobile-option p {
      font-size: 1rem;
      text-align: center;
      margin-top: 10px; /* Add margin to separate text from the image */
      font-weight: bold;
      cursor: pointer;
  }

  /* Wealth Cards */
  .wealth-cards, 
  .wealth-cards1 {
      display: flex;
      flex-direction: column;
      width: 100%;
  }

  .wealth-card, 
  .wealth-card1 {
      width: 100%;
      margin-bottom: 20px;
  }

  /* Pink Container Adjustments */
  .pink-container {
      display: flex;
      flex-direction: column; /* Stack items vertically */
      align-items: center;
      text-align: center;
      padding: 40px 20px; /* Reduce padding for smaller screens */
  }

  .pink-content {
      width: 100%; /* Full width on mobile */
  }

  .pink-button {
      width: 100%; /* Full width */
      display: flex;
      justify-content: center;
      margin-top: 20px; /* Add space above the button */
  }

  /* Ensuring Button Spacing */
  .pink-button a {
      padding: 12px 20px;
      font-size: 1rem;
      font-weight: bold;
      text-align: center;
  }


  
  .sv-container {
      display: flex;
      flex-direction: column; /* Stack title and description vertically */
      align-items: flex-start; /* Align items to the left */
      text-align: left; /* Keep text aligned left */
      padding: 20px;
  }

  .sv-content {
      display: flex;
      flex-direction: column; /* Stack title and description */
      gap: 10px; /* Add space between title and description */
  }

  .sv-title {
      order: -1; /* Ensures title is at the top */
      font-size: 20px; /* Adjust font size for mobile */
      font-weight: bold;
      margin-bottom: 5px; /* Small gap below the title */
  }

  .sv-des {
      order: 1; /* Ensures description is at the bottom */
      font-size: 14px;
      color: #5a5a5a;
      line-height: 1.5;
  }
}
