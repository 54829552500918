/* General Styles */
body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scrolling */
    width: 100%;
}

/* Hero Section Image */
.hero img {
    object-fit: cover; /* Ensures the image covers the entire container */
    width: 100vw; /* Force the image to only take up the viewport width */
    height: 100vh; /* Image takes full height of the viewport */
    position: fixed; /* Keeps the image fixed in the background */
    top: 0;
    left: 0;
    overflow-x: hidden; /* Ensure no overflow in horizontal direction */
}

/* Hero Container */
.hero-container {
    height: 100vh;
    width: 100vw; /* Ensure the container takes up full width of the viewport */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    overflow: hidden; /* Prevent any overflow */
    position: relative; /* Make sure the content stays inside the container */
}

/* Banner Content */
.banner-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #3D2230;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.banner-title {
    font-size: 4rem;
    margin-bottom: 1rem;
    color: #3D2230;
}

.banner-content h1{
  font-size: 5rem;
}
.banner-content h1::after{
    content: '';
    display: block;
    width: 100%; /* 2/3 of the width */
    height: 5px; /* Thickness of the line */
    background-color: #BB9959; /* Line color */
    margin-top: 10px;
}

.banner-content h3{
  font-size: 2rem;
}

.banner-paragraph {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
    font-style: italic;
    color: #3D2230;
}

.banner-button {
    background-color: #00000029; /* Faded background */
    color: #fff;
    padding: 15px 40px;
    border: none;
    border-radius: 10px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.banner-button:hover {
    background-color: #3D2230; /* Solid background on hover */
    color: #fff;
}

.hero-footer-text {
    position: absolute;
    bottom: 20px; /* Distance from the bottom of the image */
    width: 100%;
    text-align: center;
    color: white;
    font-size: 2rem;
    z-index: 2;
    
}

.hero-footer-text p {
    margin: 0;
    font-size: 1.7rem;
    color: #fff;
    font-style: italic;
}

/* Mobile and Tablet View Adjustments */
@media screen and (max-width: 960px) {
    .banner-title {
        font-size: 3rem; /* Smaller for tablets */
    }

    .banner-content h1 {
        font-size: 4rem;
    }

    .banner-paragraph {
        font-size: 1rem; /* Smaller paragraph font */
    }

    .banner-button {
        padding: 12px 30px;
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 768px) {
    .banner-title {
        font-size: 1.5rem; /* Reduce font size */
        line-height: 1.2; /* Adjust line spacing */
        max-width: 100%; /* Ensure it doesn’t overflow */
        word-wrap: break-word; /* Break long words if needed */
        overflow-wrap: break-word; /* Ensure text wraps properly */
    }

    .banner-content h1 {
        font-size: 3rem;
    }

    .banner-paragraph {
        font-size: 0.9rem; /* Smaller paragraph */
    }

    .banner-button {
        padding: 10px 24px;
        font-size: 0.8rem;
    }

    .hero-footer-text p {
        font-size: 0.8rem; /* Smaller footer text */
    }
}

@media screen and (max-width: 480px) {
    .banner-title {
        font-size: 2rem; /* Smaller font for small screens */
    }

    .banner-content h1 {
        font-size: 3rem; /* Reduced further for mobile */
    }

    .banner-paragraph {
        font-size: 0.8rem; /* Even smaller paragraph */
    }

    .banner-button {
        padding: 8px 20px;
        font-size: 0.8rem;
    }

    .hero-footer-text p {
        font-size: 0.8rem;
    }
}
