.advantage {
    background: #fff;
}

.advantage-items {
    padding: 6rem;
    background: #3D2230;
}

.advantage-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 1200px;
    justify-content: center;
    height: 100%;
    width: 100%;
    margin: auto;
}

.advantage-content {
    display: flex;
    align-items: center;
}

.item-50 {
    width: 50%;
}

.advantage-description {
    font-size: 1.3rem;
    top: 0;
    bottom: 0;
    margin: auto;
    margin-left: 4ch;
    width: 80%;
    float: left;
    color: #fff;
}

.advantage-title {
    color: #3D2230;
    font-size: 2.5em;
}

.advantage-title h2::after{
    content: '';
    display: block;
    width: 200px; 
    height: 5px; /* Thickness of the line */
    background-color: #BB9959; /* Line color */
    margin-top: 10px;
}

.advantage-item1{
    padding: 6rem;
    background: #fff;
}

.advantage-description1 {
    font-size: 1.4rem;
    top: 0;
    bottom: 0;
    margin: auto;
    float: left;
    color: #000;
}

.advantage-container1 {
    display: flex;
    flex-direction: column;
    align-items: left;
    max-width: 1100px;
    justify-content: left;
    height: 100%;
    width: 100%;
    margin: auto;
    text-align: left;
}

.advantage-box-container {
    

    display: flex;
    justify-content: space-between; /* Adjusts spacing between boxes */
    align-items: flex-start;       /* Aligns items to the top */
    flex-wrap: wrap;     /* Aligns items to the left */
    max-width: 1200px;             /* Sets a maximum width */
    width: 100%;                  /* Ensures the container is responsive */
    margin: 0 auto;               /* Centers the container horizontally */
    padding: 1em;  
}


.advantage-box h4::after {
    content: '';
    display: block;
    width: 40%; /* Adjust the width of the line */
    height: 2px; /* Thickness of the line */
    background-color: white; /* Line color */
    margin: 10px auto 0; /* Center the line and add space */
    position: relative;
    left: 0;
}

.advantage-box {
    flex: 1;
    height: 250px; /* Keep the height fixed */
    max-width: 330px;
    padding: 20px; /* Add padding for spacing inside the box */
    box-sizing: border-box;
    background-color: #3D2230;
    border: 3px solid #3D2230;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Align content to the top of the box */
    border-radius: 25px;
}

.advantage-description2{
    text-align: left;             /* Aligns text to the left */
    margin: 0;                    /* Removes default margin */
    line-height: 1.5em;  
    color: #fff; 
}

h4 {
    font-size: 1.2em;
    color: #fff;
    padding: 1em;
}

.advantage-different {
    padding: 6rem;
    background: #3D2230;
}

.advantage-different-title {
    font-size: 2.9rem;
    top: 0;
    bottom: 0;
    color: #fff;
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
}

.advantage-different-title h2::after {
    content: '';
    display: block;
    width: 30%; /* Adjust the width as needed */
    height: 8px; /* Thickness of the line */
    background-color: #BB9959; /* Line color */
    margin: 10px auto 0; /* Ensures the line is right under the title and centered */
    position: relative; /* Keeps it relative to the title */
    top: 0; /* Places it directly under the title */
}


.advantage-different-box {
    

    flex: 1;
    height: 250px; /* Keep the height fixed */
    max-width: 330px;
    padding: 15px; /* Add padding for spacing inside the box */
    box-sizing: border-box;
    background-color: #926C80;
    border: 3px solid #3D2230;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Align content to the top of the box */
    border-radius: 25px;
    
}

.advantage-different-box h4::after {
    content: '';
    display: block;
    width: 40%; /* Adjust the width of the line */
    height: 2px; /* Thickness of the line */
    background-color: white; /* Line color */
    margin: 10px auto 0; /* Center the line and add space */
    position: relative;
    left: 0;
}

/* Responsive Design */
@media screen and (max-width: 768px)  {
    .advantage-box-container {
        flex-direction: column; /* Stack boxes vertically */
        align-items: center; /* Center the boxes */
        width: 100%; /* Ensure the container takes the full width */
        padding: 0; /* Remove any extra padding */
        gap: 20px; /* Consistent spacing between boxes */
    }

    /* Set both .advantage-box and .advantage-different-box to 289px */
    .advantage-box,
    .advantage-different-box {
        width: 289px !important; /* Explicitly set the width to 289px */
        max-width: 100%; /* Ensure it doesn’t exceed the container */
        height: auto; /* Let height adjust based on content */
        margin: 0 auto 20px; /* Center the boxes and add space between */
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center; /* Vertically center the content */
        align-items: center; /* Horizontally center the content */
        padding: 20px;
        box-sizing: border-box; /* Include padding in width calculation */
    }
    .advantage-description1 {
        font-size: 1em;
    }
    .advantage-item1{
        padding: 2rem;
    }
    .advantage-description2 {
        text-align: center;
        margin: 0 auto;
    }

    /* Adjust the text styling for mobile */
    .advantage-box h4,
    .advantage-different-box h4 {
        font-size: 1.1em;
        margin-bottom: 10px;
    }

    .advantage-box h4::after,
    .advantage-different-box h4::after {
        content: '';
        display: block;
        width: 40%;
        height: 2px;
        background-color: white;
        margin: 10px auto 0;
    }
}
